<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Graphical Ad Report</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row mb-3" v-if="!$auth.ignoreDemo.isFeatured">
          <div class="col">
            <upgrade-to-access>
              <p>
                Upgrade to a featured listing to purchase and view reports on
                display advertising.
              </p>
            </upgrade-to-access>
          </div>
        </div>
        <div v-else class="row row-deck row-cards">
          <div class="col col-lg-3">
            <form action="" method="get">
              <site-select
                :all-sites="sites_map"
                v-on:update:selected="updateSelectedSites"
              ></site-select>

              <date-filters
                :all-filters="date_filters"
                :bus="this.filter.selected_datefilter"
                v-on:update:selected="updateSelectedDateFilter"
              ></date-filters>

              <div v-if="custom">
                <date-select
                  :label="`Start Date`"
                  v-on:update:selected="updateSelectedStartDate"
                ></date-select>

                <date-select
                  :label="`End Date`"
                  v-on:update:selected="updateSelectedEndDate"
                ></date-select>
              </div>

              <div v-if="$auth.ignoreDemo.isFeatured">
                <div class="subheader mb-2">Export</div>
                <div class="mb-3">
                  <button
                    class="btn btn-primary w-100"
                    @click.prevent="generateCSV()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-table-export"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M11.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7.5m-16 -3.5h16m-10 -6v16m4 -1h7m-3 -3l3 3l-3 3"
                      ></path>
                    </svg>
                    Download as CSV
                  </button>
                </div>
              </div>
              <div v-if="reload">
                <div class="spinner-border mt-2" role="status"></div>
              </div>
            </form>
          </div>
          <div class="col-lg-9">
            <div class="container-xl" v-if="graphicaladDTOs.length > 0">
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-end">
                    <div class="subheader mb-2" id="topOftable">
                      Showing {{ filteredGraphicalAdDTOs.length }} out of
                      {{ graphicaladDTOs.length }} Ad Placements
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card">
                    <div class="table-responsive">
                      <table
                        class="table table-vcenter card-table table-striped"
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>Placement</th>
                            <th class="text-center">Views</th>
                            <th class="text-center">Clicks</th>
                            <th class="text-center">CTR</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="graphicaladDTO in displayGraphicalAdDTOs"
                            v-bind:key="graphicaladDTO.rowid"
                          >
                            <td>
                              <div>
                                View Ad
                                <span
                                  :id="'popover_' + graphicaladDTO.rowid"
                                  v-if="graphicaladDTO.image"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-info-circle"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <circle cx="12" cy="12" r="9"></circle>
                                    <line
                                      x1="12"
                                      y1="8"
                                      x2="12.01"
                                      y2="8"
                                    ></line>
                                    <polyline
                                      points="11 12 12 12 12 16 13 16"
                                    ></polyline>
                                  </svg>
                                </span>
                                <b-popover
                                  :target="'popover_' + graphicaladDTO.rowid"
                                  triggers="hover"
                                  placement="right"
                                  v-if="graphicaladDTO.image"
                                >
                                  <img :src="graphicaladDTO.image" />
                                </b-popover>

                                <div class="text-muted small">
                                  {{ graphicaladDTO.width }} x
                                  {{ graphicaladDTO.height }}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                {{ graphicaladDTO.itemName }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                {{ graphicaladDTO.impressions }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                {{ graphicaladDTO.clicks }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center">
                                {{ graphicaladDTO.actionRate }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div>&nbsp;</div>
                            </td>
                            <td>
                              <div class="text-right font-weight-bold">
                                Total
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_views }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_clicks }}
                              </div>
                            </td>
                            <td>
                              <div class="text-center font-weight-bold">
                                {{ total_actionrate }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-xl" v-else>
              <span>
                No traffic activity has been collected yet, or you do not have
                any Graphical Advertisements.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-xl" v-else>
        <div class="row">
          <div class="col">
            <h3>Preparing graphical ad report...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px !important;
  font-size: 0.875rem;
}
</style>

<script>
import ReportService from "../../services/ReportService";
import CSVService from "../../services/CSVService";
import SiteSelect from "../../components/Reports/VisitorInsight/SiteSelect.vue";
import DateFilters from "../../components/Reports/DateFilters.vue";
import DateSelect from "../../components/Reports/DateSelect.vue";
import UpgradeToAccess from "../../components/Reports/UpgradeToAccess.vue";
import { add } from "date-fns";
import TrackingService from "../../services/TrackingService";

export default {
  name: "GraphicalAdReport",
  components: {
    SiteSelect,
    DateFilters,
    DateSelect,
    UpgradeToAccess,
  },
  data: () => {
    return {
      loaded: false,
      reload: false,
      graphicaladDTOs: [],
      sites_map: {},
      total_views: 0,
      total_clicks: 0,
      total_actionrate: 0.0,
      date_filters: [
        "Last year",
        "Year to date",
        "Last 30 days",
        "Last month",
        "Month to date",
        "Today",
        "Yesterday",
        "Custom",
      ],
      custom: false,
      rows: 0,
      filter: {
        selected_categories: [],
        selected_sites: [],
        selected_datefilter: "",
        selected_startdate: "",
        selected_enddate: "",
      },
    };
  },
  methods: {
    generateCSV() {
      TrackingService.track("download graphical ad report csv");

      let fields = [
        {
          id: "AdSize",
          getter: (r) => (r["width"] ? r["width"] + " x " + r["height"] : null),
        },
        {
          id: "Placement",
          getter: (r) => r["itemName"],
        },
        {
          id: "Views",
          getter: (r) => r["impressions"],
        },
        {
          id: "Clicks",
          getter: (r) => r["clicks"],
        },
        {
          id: "Click_Through_Rate",
          getter: (r) => r["actionRate"],
        },
      ];

      let totalGraphicalAdDTO = {
        rowid: null,
        siteName: null,
        siteid: null,
        itemName: "Total",
        adId: null,
        adformatId: null,
        url: null,
        image: null,
        locationId: null,
        topicId: null,
        impressions: this.total_views,
        clicks: this.total_clicks,
        actionRate: this.total_actionrate,
        width: null,
        height: null,
      };

      let data = this.filteredGraphicalAdDTOs;
      data.push(totalGraphicalAdDTO);

      let csv = CSVService.serialize({
        fields: fields,
        records: data,
      });

      let download = document.createElement("a");
      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      let url = URL.createObjectURL(blob);
      download.href = url;
      download.setAttribute("download", "graphical_ad_export.csv");
      download.click();
    },
    updateSelectedSites(selected) {
      this.$set(this.filter, "selected_sites", selected);

      // clear
      this.total_views = 0;
      this.total_clicks = 0;

      // iterate through all FILTERED graphical ad rows
      this.graphicaladDTOs.forEach((n) => {
        if (
          this.filter.selected_sites.indexOf(n.siteName) >= 0 ||
          this.filter.selected_sites.length == 0
        ) {
          // keep running view total
          this.total_views += n.impressions;

          // keep running view total
          this.total_clicks += n.clicks;
        }
      });

      // calculate total actionrate
      this.total_actionrate =
        this.total_clicks / (this.total_views == 0 ? 1 : this.total_views);

      // format total actionrate
      this.total_actionrate =
        (Number((this.total_actionrate * 100).toFixed(2)) == 0.0
          ? "0.00"
          : Number((this.total_actionrate * 100).toFixed(2))) + "%";
    },
    updateSelectedDateFilter(selected) {
      TrackingService.track("change graphical ad report filter");

      // set the selected datefilter
      this.$set(this.filter, "selected_datefilter", selected);

      // set custom flag
      this.custom = selected == "Custom" ? true : false;

      // set appropriate dates for filter
      switch (selected) {
        case "":
          this.$set(this.filter, "selected_startdate", "");
          this.$set(this.filter, "selected_enddate", "");
          break;
        case "Last 12 months":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { years: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last year":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(new Date().getFullYear(), 0, 1), { years: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(add(new Date(new Date().getFullYear(), 0, 1), { days: -1 }))
          );
          break;
        case "Year to date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date(new Date().getFullYear(), 0, 1))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last 30 days":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { days: -30 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Last month":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(new Date().getFullYear(), new Date().getMonth(), 1), { months: -1 }))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(add(new Date(new Date().getFullYear(), new Date().getMonth(), 1), { days: -1 }))
          );
          break;
        case "Month to date":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Today":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(new Date())
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        case "Yesterday":
          this.$set(
            this.filter,
            "selected_startdate",
            this.getFormattedDate(add(new Date(), { days: -1}))
          );
          this.$set(
            this.filter,
            "selected_enddate",
            this.getFormattedDate(new Date())
          );
          break;
        default:
          break;
      }

      // load ad data for company
      this.getGraphicalAdData(true);
    },
    updateSelectedStartDate(selected) {
      this.$set(this.filter, "selected_startdate", selected);
      if (this.filter.selected_enddate != "") {
        this.getGraphicalAdData(true);
      }
    },
    updateSelectedEndDate(selected) {
      this.$set(this.filter, "selected_enddate", selected);
      this.getGraphicalAdData(true);
    },
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },
    async getGraphicalAdData(isreload) {
      let company = this.$auth.activeCompany;

      let reportFilter = {
        Company: { Companyid: company.companyId },
        StartDate: this.filter.selected_startdate,
        EndDate: this.filter.selected_enddate,
      };

      // mark as reload or not
      this.reload = isreload;

      // make service call
      let graphicaladDTOs = await ReportService.getGraphicalAdReport(
        reportFilter
      );

      // clear
      this.total_views = 0;
      this.total_clicks = 0;
      this.total_actionrate = 0.0;
      this.sites_map = {};

      graphicaladDTOs.forEach((n) => {
        // populate available sites returned from output
        if (!this.sites_map[n.siteName]) {
          this.sites_map[n.siteName] = 0;
        }
        this.sites_map[n.siteName] += 1;

        // incrment rows
        this.rows++;

        // build rowid
        n.rowid = this.rows;

        // prepare image
        n.image = n.image
          ? process.env.VUE_APP_CLIENT_IMAGE_URL + n.image
          : n.image;

        // keep running view total
        this.total_views += n.impressions;

        // keep running view total
        this.total_clicks += n.clicks;

        // format action rate
        n.actionRate =
          (Number((n.actionRate * 100).toFixed(2)) == 0.0
            ? "0.00"
            : Number((n.actionRate * 100).toFixed(2))) + "%";
      });

      // sort by sitename
      this.graphicaladDTOs = graphicaladDTOs.sort((a) => a.siteName);

      // calculate total actionrate
      this.total_actionrate =
        this.total_clicks / (this.total_views == 0 ? 1 : this.total_views);

      // format total actionrate
      this.total_actionrate =
        (Number((this.total_actionrate * 100).toFixed(2)) == 0.0
          ? "0.00"
          : Number((this.total_actionrate * 100).toFixed(2))) + "%";

      // mark as loaded
      this.loaded = true;

      // mark as reloaded
      this.reload = false;
    },
  },
  computed: {
    filteredGraphicalAdDTOs() {
      return this.graphicaladDTOs.filter((n) => {
        if (this.filter.selected_sites.length == 0) return true;
        return this.filter.selected_sites.indexOf(n.siteName) >= 0;
      });
    },
    displayGraphicalAdDTOs() {
      return this.filteredGraphicalAdDTOs;
    },
  },
  async mounted() {
    // load ad data for company
    this.getGraphicalAdData(false);

    TrackingService.track("viewed graphical ad report");
  },
};
</script>
